<template>
  <div class="bindUser">
    <div class="bind_user_cnt">
      <p class="bind_user_title">账号绑定</p>
      <div class="bind_user_main">
        <img src="../assets/image/bind_user_img.png" alt="" />
        <div class="bind_user_form">
          <el-form ref="form" :model="form">
            <el-form-item label="绑定账号：">
              <el-input
                v-model.trim="form.userName"
                placeholder="请输入账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="密码：">
              <el-input
                type="password"
                v-model.trim="form.passWord"
                placeholder="请输入账号密码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">保存</el-button>
              <el-button @click="backHome()">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bindUser",
  props: {},
  components: {},
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
      },
    };
  },
  mounted() {
    // this.encryptionBeforre();
  },
  methods: {
    encryptionBeforre() {
      this.$api.encryptionBeforre().then((data) => {
        let res = data.data;
        if (res.code === "1") {
          let sexadecimalModulus = res.data.sexadecimalModulus;
          // 获取公钥
          let sexadecimalExponent = res.data.sexadecimalExponent;
          // 获取用来解密的key
          let modulus = res.data.modulus;
          let key = new RSAUtilsV2.getKeyPair(
            sexadecimalExponent,
            "",
            sexadecimalModulus
          );
          console.log(modulus, key);
          let rsaPassWord = RSAUtilsV2.encryptedString(
            key,
            this.form.passWord.split("").reverse().join("")
          );
          console.log(rsaPassWord);
          this.thirdPartyBind(rsaPassWord, modulus);
        }
      });
    },
    thirdPartyBind(password, modulus) {
      let params = {
        password: password,
        modulus: modulus,
        loginName: this.form.userName,
        type: this.$route.query.type,
        openId: this.$route.query.openId,
      };
      console.log(params);
      this.$api.thirdPartyBind(params).then((data) => {
        let res = data.data;
        console.log(res);
        if (res.code === "1") {
          this.$message.success("账号绑定成功");
          this.successBind();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    successBind() {
      // let params = {
      //   type: this.$route.query.type,
      //   openId: this.$route.query.openId,
      // };
      window.location.href = `https://${window.location.host}/desktop/backend/bind/successBind?type=${this.$route.query.type}&openId=${this.$route.query.openId}`;
      // this.$api.successBind(params).then((res) => {});
    },
    onSubmit() {
      if (!this.form.userName) {
        this.$message.warning("请输入账号");
        return false;
      }
      if (!this.form.passWord) {
        this.$message.warning("请输入密码");
        return false;
      }
      this.encryptionBeforre();
    },
    backHome() {
      window.location.href = `https://${window.location.host}`;
    },
  },
};
</script>

<style scoped lang="scss">
.bindUser {
  width: 100%;
  min-width: 1200px;
  height: 1024px;
  background: url("../assets/image/bind_user_bg.png") no-repeat top center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 84px;
}
.bind_user_cnt {
  width: 900px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(185, 200, 225, 0.43);
  border-radius: 16px;
  overflow: hidden;
}
.bind_user_title {
  background: linear-gradient(360deg, #f7faff 0%, #e1edff 100%);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #262626;
  line-height: 22px;
  padding: 19px 0;
  text-align: center;
}
.bind_user_main {
  padding: 28px 60px;
  display: flex;
  justify-content: space-between;
  img {
    width: 325px;
    height: 316px;
  }
  .bind_user_form {
    width: 374px;
    padding: 22px 20px 0 0;
    .el-button {
      padding: 12px 25px;
    }
  }
}
</style>
